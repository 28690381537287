module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFFFFF","display":"minimal-ui","icon":"./src/images/logo/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-149383446-1","exclude":["/cookie/*","/privacy/*"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-iubenda-cookie-footer/gatsby-browser.js'),
      options: {"plugins":[],"iubendaOptions":{"consentOnContinuedBrowsing":false,"whitelabel":false,"lang":"it","siteId":1695407,"cookiePolicyId":75049012,"cookiePolicyUrl":"/cookie/","perPurposeConsent":true,"banner":{"acceptButtonDisplay":true,"customizeButtonDisplay":true,"position":"float-bottom-center","acceptButtonColor":"#1c428b","acceptButtonCaptionColor":"white","customizeButtonColor":"#76dec4","customizeButtonCaptionColor":"#000000","rejectButtonColor":"#0073CE","rejectButtonCaptionColor":"white","textColor":"#000000","backgroundColor":"#ffffff"}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
