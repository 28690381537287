/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"
import { ThemeProvider } from "styled-components"

export const wrapPageElement = ({ element }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it

  const theme = {
    white: "#FFFFFF",
    jonquil: "#F5D410",
    yaleBlue: "#1C428B",
    pearlAqua: "#76DEC4",
    pearl: "#E8F4C9",
    isabelline: "#F7F5F2",
    ashGrey: "#B4B4B4",
    charlestonGrey: "#272727",
    primaryTextColor: "#2E2E2E",
    blueTextColor: "#003496",
    logoBorderColor: "#3F3E3E",
    mediaQueries: {
      mobile: "450px",
      tablet: "1112px",
    },
  }

  return <ThemeProvider theme={theme}>{element}</ThemeProvider>
}
